import { Doughnut, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;
var plugin = function(chart) {
	var width = chart.chart.width;
	var height = chart.chart.height;
	var ctx = chart.chart.ctx;

	ctx.restore();
	var fontSize = (height / 114).toFixed(2);
	ctx.font = fontSize + "em sans-serif";
	ctx.fillStyle = '#ffffff'
	ctx.textBaseline = "middle";

	var text = "Status";
	var textX = Math.round((width - ctx.measureText(text).width) / 2);
	var textY = height / 2;

	 ctx.fillText(text, textX, textY);
	 ctx.save();
}
export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    options: {
      required: false,
      type: Object,
      default: () => ({
        responsive: false,
        maintainAspectRatio: false,
      }),
    },
    renderEvent: {
      required: false,
      type: String,
      default: '',
    },
  },
  mounted() {
    // @ts-ignore
	this.addPlugin({
		id: 'my-plugin',
		beforeDraw: plugin
	  })
	// @ts-ignore
    this.renderChart(this.chartData, this.options);
  },
};
